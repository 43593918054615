import React, { useState } from "react";

function Index() {
  const [value, setValue] = useState(0);
  return (
    <>
      <div id="team" className="mx-auto container px-4 xl:px-0 pt-28 md:pt-32">
        <div className="flex justify-center w-full">
          <div className="w-full xl:w-10/12 2xl:w-9/12">
            <div className="lg:flex-row flex flex-col items-center justify-center lg:justify-between lg:space-x-24">
              <div className="w-full flex justify-center lg:w-2/5">
                <div
                  style={{
                    background: `url(${
                      (value === 0 && "images/t1.png") ||
                      (value === 1 && "images/t2.png") ||
                      (value === 2 && "images/t3.png")
                    })`,
                  }}
                  className="w-360 h-360 sm:h-414 sm:w-414 bg-cover bg-center flex flex-col items-end justify-end rounded-10 px-6 pb-6 bg-no-repeat"
                >
                  <>
                    <div className="bggrad w-full py-4 md:py-6 rounded-lg border-2 border-black flex flex-col items-center justify-center">
                      <p className="text-black1 text-xl lg:text-2xl md:leading-6 font-bold">
                        {(value === 0 && "Crasher - Founder") ||
                          (value === 1 && "Elias - Developer") ||
                          (value === 2 && "Fuze - Artist")}
                      </p>
                      <p
                        style={{ color: "#3F3F46" }}
                        className="text-sm md:text-base md:leading-4 mt-2 md:mt-3 font-bold"
                      >
                        {(value === 0 && " Marketing Director & SMM") ||
                          (value === 1 && "Frontend / Backend Programmer") ||
                          (value === 2 && "Protools Artist & Management")}
                      </p>
                    </div>
                  </>
                </div>
              </div>
              <div className="lg:w-7/12 w-full flex justify-center 2xl:justify-end">
                <div className="lg:w-4/5 flex flex-col-reverse lg:flex-col items-center justify-center">
                  <div className="mt-6 md:mt-10 lg:mt-0">
                    <p className="text-white text-center lg:text-left text-3xl md:text-4xl lg:text-5xl font-bold lg:leading-48 f-f-h">
                      Team
                    </p>
                    <p className="mt-6 gray leading-6 text-center lg:text-left  2xl:pr-20">
                      We are a hardworking and enthusiastic team with members
                      from all over the globe. We had the idea almost 3 months
                      ago, and with careful analysis of other projects we are
                      putting our foot in the water with Tired Sloth Troop!
                    </p>
                  </div>
                  <div className="flex items-center space-x-6 md:space-x-12 mt-8 lg:mt-48 w-full justify-between md:justify-center lg:justify-between">
                    <button
                      onClick={() => {
                        value === 0 ? setValue(0) : setValue(value - 1);
                      }}
                      className="focus:outline-none flex-shrink-0"
                    >
                      <svg
                        width={49}
                        height={16}
                        viewBox="0 0 49 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.292893 7.2929C-0.0976311 7.68342 -0.097631 8.31659 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95263 8.46159 1.31946 8.07107 0.928936C7.68054 0.538412 7.04738 0.538412 6.65685 0.928936L0.292893 7.2929ZM49 7L1 7L1 9L49 9L49 7Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                    <div className="flex items-center space-x-4 md:space-x-6">
                      <img
                        src={`images/ts1.png`}
                        onClick={() => setValue(0)}
                        alt={`team member 1`}
                        className={`${
                          value === 0 ? " border-green1" : "border-gray2"
                        } border rounded-full cursor-pointer flex-shrink-0 w-10 h-10 sm:w-14 sm:h-14`}
                      />
                      <img
                        src={`images/ts2.png`}
                        onClick={() => setValue(1)}
                        alt={`team member 2`}
                        className={`${
                          value === 1 ? " border-green1" : "border-gray2"
                        } border rounded-full cursor-pointer flex-shrink-0 w-10 h-10 sm:w-14 sm:h-14`}
                      />
                      <img
                        src={`images/ts3.png`}
                        onClick={() => setValue(2)}
                        alt={`team member 3`}
                        className={`${
                          value === 2 ? " border-green1" : "border-gray2"
                        } border rounded-full cursor-pointer flex-shrink-0 w-10 h-10 sm:w-14 sm:h-14`}
                      />
                    </div>
                    <button
                      onClick={() => {
                        value === 2 ? setValue(2) : setValue(value + 1);
                      }}
                      className="focus:outline-none flex-shrink-0"
                    >
                      <svg
                        width={49}
                        height={16}
                        viewBox="0 0 49 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M48.7071 8.70712C49.0976 8.31659 49.0976 7.68343 48.7071 7.2929L42.3431 0.92894C41.9526 0.538415 41.3195 0.538415 40.9289 0.928939C40.5384 1.31946 40.5384 1.95263 40.9289 2.34315L46.5858 8.00001L40.9289 13.6569C40.5384 14.0474 40.5384 14.6806 40.9289 15.0711C41.3195 15.4616 41.9526 15.4616 42.3431 15.0711L48.7071 8.70712ZM-1.74846e-07 9L48 9.00001L48 7.00001L1.74846e-07 7L-1.74846e-07 9Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
