import React from "react";
import Button from "../../common/button/index";
function Index() {
  const onJoinCommunity = () => {
    window.open("https://discord.gg/bknpp4RkCX", "_blank");
  };

  return (
    <div id="home" className="heroBg ">
      <div className="mx-auto container px-4 xl:px-0 pt-20 xl:pt-28">
        <div className="flex flex-col items-center justify-center">
          <img
            className="w-1/4  md:w-1/6 xl:w-auto"
            src="images/hero1.png"
            alt="hero main"
          />

          <p className="text-white text-sm text-center md:text-base mt-4 md:mt-8  2xl:mt-12 lg:text-lg xl:text-xl lg:leading-30  w-full md:w-11/12 lg:w-9/12 2xl:w-8/12">
            A group of the laziest sloths in the world have gathered and formed
            a militant troop… <br />
            The most RELAXED troop ever.
          </p>
          <div className="mt-4 md:mt-8 lg:mt-10">
            <Button
              purchase={true}
              title="Join Community"
              onClickHandler={onJoinCommunity}
            />
          </div>
          {/* <div className="rounded-21 shadow-shadow1 border border-green1 sm:w-412 sm:h-412 h-360 w-360 margins relative">
            <img
              src="images/sloths.gif"
              alt="gif"
              className="w-full h-full rounded-21"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Index;
