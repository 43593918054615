import React, { useState, useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import Slide from "react-reveal/Slide";
import MintBtn from "../common/button";
import { useLocation } from "react-router-dom";
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

function Header() {
  const [scroll, setScroll] = useState(false);
  const [menu, setmenu] = useState(false);
  const location = useLocation();
  const [item, setItem] = useState("home");
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);

  useEffect(() => {
    window.onscroll = function () {
      let home = document.getElementById("home");
      let info = document.getElementById("info");
      let collection = document.getElementById("collection");
      let roadmap = document.getElementById("roadmap");
      let team = document.getElementById("team");
      let faq = document.getElementById("faq");
      if (location.pathname === "/") {
        isElementInViewport(home);
        isElementInViewport(info);
        isElementInViewport(collection);
        isElementInViewport(roadmap);
        isElementInViewport(team);
        isElementInViewport(faq);
      }
    };
  });
  function isElementInViewport(el) {
    var rect = location.pathname === "/" && el.getBoundingClientRect();
    if (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    ) {
      setItem(el.id);
    }
  }

  return (
    <div
      className={`fixed top-0 w-full bg-black1 ${scroll ? "bg-opacity-100" : "bg-opacity-50"
        } transition-all duration-300 ease-in-out z-30  h-71 flex flex-col items-center justify-center border border-black1 rounded-bl-lg rounded-br-lg`}
    >
      <div className="mx-auto container w-full px-4 md:px-8 xl:px-0">
        <div className="flex w-full items-center justify-between">
          <Link to="/">
            <img alt="logo" src="images/logo.png" />
          </Link>
          <ul className="lg:flex items-center space-x-6 xl:space-x-8 hidden">
            <li
              className={`${item === "home" && location.pathname === "/"
                ? "text-white"
                : "text-gray3"
                } text-base xl:text-xl leading-150 hover:text-white transition-all duration-200 ease-in-out`}
            >
              <Link to="/#home">Home</Link>
            </li>
            <li
              className={`${item === "info" && location.pathname === "/"
                ? "text-white"
                : "text-gray3"
                } text-white text-base xl:text-xl leading-150 hover:text-white transition-all duration-200 ease-in-out`}
            >
              <Link to="/#info">What it is</Link>
            </li>
            <li
              className={`${item === "collection" && location.pathname === "/"
                ? "text-white"
                : "text-gray3"
                } text-base xl:text-xl leading-150 hover:text-white transition-all duration-200 ease-in-out`}
            >
              <Link to="/#collection">Collection</Link>
            </li>
            <li
              className={`${item === "roadmap" && location.pathname === "/"
                ? "text-white"
                : "text-gray3"
                } text-base xl:text-xl leading-150 hover:text-white transition-all duration-200 ease-in-out`}
            >
              <Link to="/#roadmap">Roadmap</Link>
            </li>
            <li
              className={`${item === "team" && location.pathname === "/"
                ? "text-white"
                : "text-gray3"
                } text-base xl:text-xl leading-150 hover:text-white transition-all duration-200 ease-in-out`}
            >
              <Link to="/#team">Team</Link>
            </li>
            <li
              className={`${item === "faq" && location.pathname === "/"
                ? "text-white"
                : "text-gray3"
                } text-white text-base xl:text-xl leading-150 hover:text-white transition-all duration-300 ease-in-out`}
            >
              <Link to="/#faq">FAQ</Link>
            </li>
            <li>
              {location.pathname === "/" ? (
                <Link to="/mint">
                  <MintBtn
                    title="Mint Here"
                    disabled={false}
                  />
                </Link>
              ) : (
                <WalletMultiButton className="grad" />
              )}
            </li>
          </ul>
          <button
            onClick={() => setmenu(true)}
            className="text-white lg:hidden focus-within:outline-none md:-mr-4 "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className=" icon icon-tabler icon-tabler-menu-2"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <line x1={4} y1={6} x2={20} y2={6} />
              <line x1={4} y1={12} x2={20} y2={12} />
              <line x1={4} y1={18} x2={20} y2={18} />
            </svg>
          </button>
        </div>
      </div>

      <Slide left>
        <div
          className={
            menu
              ? "z-50 w-1/2 sm:w-1/3 sm:overflow-y-auto sm:h-full bg-black1  rounded-tr-2xl fixed min-h-screen -ml-2 sm:-ml-4 md:-ml-2 top-0 left-0 shadow-2xl  lg:hidden"
              : "hidden"
          }
        >
          <div className="flex justify-between w-full p-8">
            <Link to="/">
              <img alt="logo" src="images/logo.png" />
            </Link>
            <button
              className="focus:outline-none text-white"
              onClick={() => setmenu(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M15 1L1 15"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 1L15 15"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <ul className="flex flex-col text-xs pl-8 space-y-6 uppercase my-4 md:mt-8">
            <Link to="/#home">
              <li className="text-white text-sm leading-4 hover:text-green1 transition duration-200 ease-in-out ">
                Home
              </li>
            </Link>
            <Link to="/#info">
              <li className="text-white text-sm leading-4 hover:text-green1 transition duration-200 ease-in-out ">
                What it is
              </li>
            </Link>
            <Link to="/#collection">
              <li className="text-white text-sm leading-4 hover:text-green1 transition duration-200 ease-in-out ">
                Collection
              </li>
            </Link>
            <Link to="/#roadmap">
              <li className="text-white text-sm leading-4 hover:text-green1 transition duration-200 ease-in-out ">
                Roadmap
              </li>
            </Link>
            <Link to="/#team">
              <li className="text-white text-bsmleading-4 hover:text-green1 transition duration-200 ease-in-out ">
                Team
              </li>
            </Link>
            <Link to="/#faq">
              <li className="text-white text-sm leading-4 hover:text-green1 transition duration-200 ease-in-out ">
                FAQ
              </li>
            </Link>
            <li>
              {location.pathname === "/" ? (
                <Link to="/mint">
                  <MintBtn
                    title="Mint Here"
                    disabled={false}
                  />
                </Link>
              ) : (
                <WalletMultiButton className="grad" />
              )}
            </li>
          </ul>
        </div>
      </Slide>
    </div>
  );
}

export default Header;
