import React, { useState } from "react";
import Fade from "react-reveal";
function Index() {
  const [ans, setAns] = useState(null);
  const faq = [
    {
      q: `Where can I find the Sloths?`,
      ans: `All of our official links are in the header of our website!      `,
    },
    {
      q: `How many Sloths are there?`,
      ans: `Our total supply is 6000. A handful will be set aside for giveaways and promotion.      `,
    },

    {
      q: ` What is the mint price?`,
      ans: `Mint price is 0.6 SOL (& gas), which entitles you to everything to in our roadmap!      `,
    },
    {
      q: `When does minting begin?`,
      ans: `November! Exact date is the 12th of December, and we will be constantly updating the Troop in our Discord server.      `,
    },
    {
      q: `What makes the Troop different? `,
      ans: `Not only will holders receive 100% of secondary sales, we will be donating to multiple charities for various causes that will be voted for in our Discord! This is on top of over $50,000 in pre-planned giveaways (with many more to come) and inclusion on all future developments / incentives.`,
    },
  ];
  return (
    <div className="mx-auto container px-4 xl:px-0 ">
      <div className="flex items-center justify-center">
        <div className="w-full md:w-11/12 xl:w-9/12 2xl:w-8/12 ">
          <div className="flex flex-col items-center justify-center">
            <p
              id="faq"
              className="text-white text-2xl md:text-4xl lg:text-5xl font-bold lg:leading-48 pt-20 md:pt-32 -mb-96 pb-96 f-f-h"
            >
              Frequently Asked Questions
            </p>
            <div className="mt-12 w-full">
              {faq.map((item, i) => {
                return (
                  <div
                    id={`${i}ch`}
                    onClick={() => {
                      ans === i ? setAns(null) : setAns(i);
                    }}
                    className={` ${i !== 0 && ans !== i && "mt-5"} ${ans === i ? "bg-transparent pt-8" : "bg-black2 py-6 "
                      }  cursor-pointer flex-shrink-0 w-full   rounded-10 px-6 md:px-8 xl:px-10 transition-all duration-300 ease-in-out`}
                  >
                    <div className="flex items-start text-sm md:text-lg lg:text-xl   font-bold text-white2  lg:leading-30  text-white w-full justify-between">
                      <p>{item.q}</p>
                      <p className="2xl:ml-4">{ans === i ? "-" : "+"}</p>
                    </div>
                    {ans === i && (
                      <Fade collapse bottom>
                        <p
                          style={{ color: "rgba(255, 255, 255, 0.5)" }}
                          className={` text-xs sm:text-sm md:text-base  mt-2 sm:mt-3 lg:leading-150`}
                        >
                          {item.ans}
                        </p>
                      </Fade>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
