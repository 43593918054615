import React, { useState } from "react";

function Index() {
  const percent = [
    {
      num: `25%`,
    },
    {
      num: `50%`,
    },
    {
      num: `75%`,
    },
    {
      num: `100%`,
    },
  ];
  const [value, setValue] = useState(0);
  return (
    <div id="roadmap" className="mx-auto container px-4 xl:px-0 mt-20 md:mt-32">
      <div className="flex justify-center w-full">
        <div className="w-full xl:w-10/12 2xl:w-9/12">
          <div
            className="w-full lg:flex-row flex flex-col items-center lg:items-start justify-center lg:justify-between lg:space-x-24"
            style={{ alignItems: "center" }}
          >
            <div className="w-full lg:w-5/12">
              <p className="text-white text-3xl md:text-4xl lg:text-5xl font-bold lg:leading-48 f-f-h text-center lg:text-left">
                Roadmap
              </p>
              <p className="mt-6 gray text-sm text-center lg:text-left md:leading-6 md:text-base">
                This is only our First Strike. Our roadmap will be updated as
                the project progresses, bringing more exciting incentives and
                fun to the whole Troop!
              </p>
            </div>
            <div className="w-full lg:w-3/5 lg:flex justify-end mt-8 lg:mt-0">
              <div className="flex flex-col items-center justify-center md:flex-row md:space-x-10">
                <div className="md:block hidden">
                  {percent.map((item, i) => {
                    return (
                      <div className="flex flex-col items-center justify-center">
                        <button
                          style={{ background: "#272727" }}
                          onClick={() => setValue(i)}
                          className={`${
                            i === value ? " border-green1" : "border-gray2"
                          } focus:outline-none border w-50 h-50 flex flex-col items-center justify-center text-sm rounded-full text-white font-bold`}
                        >
                          {" "}
                          {item.num}
                        </button>
                        {i !== percent.length - 1 && (
                          <div className="w-1 h-6 bg-gray1" />
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="flex items-center md:hidden space-x-3 sm:space-x-4">
                  {percent.map((item, i) => {
                    return (
                      <button
                        style={{ background: "#272727" }}
                        onClick={() => setValue(i)}
                        className={`${
                          i === value ? " border-green1" : "border-gray2"
                        } focus:outline-none border w-12 h-12 sm:w-50 sm:h-50 flex flex-col items-center justify-center text-xs sm:text-sm rounded-full text-white font-bold`}
                      >
                        {" "}
                        {item.num}
                      </button>
                    );
                  })}
                </div>
                <div
                  style={{ background: "url(images/roadmap.png),#272727" }}
                  className="rounded-10 w-360 h-360 sm:h-420 sm:w-414 py-6 md:py-10 bg-cover bg-center px-4 sm:px-6  md:px-8  mt-8 md:mt-0"
                >
                  {value === 0 && (
                    <div className="flex flex-col justify-between h-full">
                      <div>
                        <p className="text-white text-base md:text-xl leading-4 font-bold">
                          25% minted: Sloth Invasion!
                        </p>
                        <p className="mt-2 gray text-sm leading-6">
                          - $20,000 into Sloth Treasury (used for future
                          incentives, marketing & promotion) <br />
                          - Rarity Sniper and/or Rarity.tools Listing <br />
                          - Sloth giveaways, challenges, etc. <br />
                        </p>
                        <br />
                        <br />
                        <p className="gray text-sm leading-6">
                        If you have any further questions, feel free to message
                        in our Discord server!
                        </p>
                      </div>
                      <div className="flex items-center justify-center w-full mt-4">
                        <img
                          className="w-2/5"
                          alt="first strike text"
                          src="images/1strike.png"
                        />
                      </div>
                    </div>
                  )}
                  {value === 1 && (
                    <div className="flex flex-col justify-between h-full">
                      <div>
                        <p className="text-white text-base md:text-xl leading-4 font-bold">
                          50% minted: Pave The Way
                        </p>
                        <p className="mt-2 gray text-sm leading-6">
                          - 30 SOL giveaway <br />- $10,000 into Sloth Treasury
                        </p>
                        <br />
                        <br />
                        <p className="gray text-sm leading-6">
                        If you have any further questions, feel free to message
                        in our Discord server!
                        </p>
                      </div>
                      <div className="flex items-center justify-center w-full mt-4">
                        <img
                          className="w-2/5"
                          alt="first strike text"
                          src="images/1strike.png"
                        />
                      </div>
                    </div>
                  )}

                  {value === 2 && (
                    <div className="flex flex-col justify-between h-full">
                      <div>
                        <p className="text-white text-base md:text-xl leading-4 font-bold">
                          75% minted: Troop-wear
                        </p>
                        <p className="mt-2 gray text-sm leading-6">
                          - Tired Troop merchandise launches
                          <br />- $10,000 into Sloth Treasury
                        </p>
                        <br />
                        <br />
                        <p className="gray text-sm leading-6">
                        If you have any further questions, feel free to message
                        in our Discord server!
                        </p>
                      </div>
                      <div className="flex items-center justify-center w-full mt-4">
                        <img
                          className="w-2/5"
                          alt="first strike text"
                          src="images/1strike.png"
                        />
                      </div>
                    </div>
                  )}
                  {value === 3 && (
                    <div className="flex flex-col justify-between h-full">
                      <div>
                        <p className="text-white text-base md:text-xl leading-4 font-bold">
                          100% minted: Giving Back
                        </p>
                        <p className="mt-2 gray text-sm leading-6">
                          - 3x Mutant Ape Yacht Club giveaway <br /> - FREE
                          Tired __ Troop NFT to our Sloth holders! (+gas)
                          <br />
                          <br />
                          If you have any further questions, feel free to
                          message in our Discord server!
                        </p>
                      </div>
                      <div className="flex items-center justify-center w-full mt-4">
                        <img
                          className="w-2/5"
                          alt="first strike text"
                          src="images/1strike.png"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
