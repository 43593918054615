import React from "react";

function Index() {
  return (
    <div id="info" className="w-full mt-11 ">
      <div className="margin">
        <div className="flex items-center justify-center flex-col">
          <p className="text-white text-3xl md:text-4xl lg:text-5xl font-bold lg:leading-48 f-f-h">
            What it is
          </p>
          <div className="relative px-4 xl:px-0 pb-16 overflow-hidden">
            <img
              className="object-cover object-center w-full h-full absolute md:block hidden mt-10"
              src="images/sloths.png"
              alt="sloths"
            />
            <div className="flex items-center justify-center flex-col mt-3">
              <div className="md:px-9 w-full md:w-11/12 lg:w-8/12 2xl:w-7/12">
                <p className="text-center text-sm md:text-base md:leading-22 gray  mt-6">
                  6,000 provably unique sloths curated and drawn by hand, the
                  Tired Sloth Troop aims to be one of the most sustainable and
                  entertaining NFT projects on the Solana Blockchain.
                </p>
                <p className="text-center text-sm md:text-base md:leading-22 gray  mt-3 md:mt-4">
                  Your Tired Sloth Token acts as an exclusive pass as a Troop
                  member to royalty rewards, contests, giveaways, and more. Our
                  treasury wallet will be used to budget marketing and promotion
                  publicly as well as support other NFT projects in the Solana
                  scene. The First Strike outlines our current roadmap plans,
                  and the Troop Generals will be constantly updating the Plan of
                  Attack as we progress.
                </p>
                <p className="text-center text-sm md:text-base md:leading-22 gray  mt-3 md:mt-4">
                  <span className="font-bold">
                    We plan on making a difference.
                  </span>
                  <br /> Mental health awareness, food banks, youth development
                  & homeless shelter services are just some of the things the
                  Troop is looking to campaign for. The Plan of Attack will
                  detail when, and which charities will be donated to.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
