import React from "react";
import Hero from "../components/homePage/hero";
import Info from "../components/homePage/info";
import Collection from "../components/homePage/collection";
import Roadmap from "../components/homePage/roadmap";
import Team from "../components/homePage/team";
import Faq from "../components/homePage/faq";

function Home() {
  return (
    <>
      <Hero />
      <Info />
      <Collection />
      <Roadmap />
      <Team />
      <Faq />
    </>
  );
}

export default Home;
