import React from "react";
import MainComponent from "../components/mintPage/index";
function Mint() {
  return (
    <>
      <MainComponent />
    </>
  );
}

export default Mint;
