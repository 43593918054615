import React, { useState } from "react";
import Button from "../common/button";
import ProgressBar from "../common/progressBar";
import useCandyMachine from '../../hooks/use-candy-machine.ts';
import { MINT_PRICE_SOL } from "../../utils/constant.ts";
import Countdown from 'react-countdown';

function Index() {

  const max = 20;
  const [value, setValue] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const { isSoldOut, mintStartDate, isMinting, onMintNFT, nftsData } = useCandyMachine();

  return (
    <div id="home" className="mt-24  xl:mt-135 overflow-hidden">
      <img
        className="object-cover object-center w-full z-0  absolute "
        src="images/sloths.png"
        alt="sloths"
      />
      <div className="mx-auto container px-4 xl:px-0">
        <div className="flex flex-col items-center justify-center relative Z-30">
          <p className="text-white text-3xl md:text-4xl lg:text-5xl font-bold lg:leading-48 f-f-h text-center">
           { !isSoldOut ? "Mint now Live" : "Sold Out" }
          </p>

          <div className="sm:w-412  w-360">
            <div className="rounded-21 shadow-shadow1 mt-10 md:mt-14 bggrad p-1 w-full sm:h-412 h-360   relative">
              <img
                src="images/sloths.gif"
                alt="gif"
                className="w-full h-full rounded-21"
              />
            </div>
            {
              isSoldOut ?
                <div className="w-full mt-12">
                  <ProgressBar percent={(nftsData.itemsRedeemed / nftsData.itemsAvailable) * 100} redeemed={nftsData.itemsRedeemed} total={nftsData.itemsAvailable} />
                </div> : ""
            }
            {isActive ?
              <div className="flex flex-col items-center space-y-3 justify-between  mt-8">
                <div
                  style={{ background: "#121214" }}
                  className="w-full rounded-lg text-white px-4 md:px-6 lg:px-7 py-3 md:py-18 border border-black2 text-xs sm:texts-m md:text-base md:leading-4 flex items-center justify-between"
                >
                  <span>Amount:</span>

                  <div className="flex items-center space-x-2.5">
                    <button
                      onClick={() => {
                        value >= 1 && setValue(value - 1);
                      }}
                      className="focus:outline-none"
                    >
                      -
                    </button>
                    <p className=" text-center w-7">{value}</p>
                    <button
                      onClick={() => {
                        value === max ? setValue(max) : setValue(value + 1);
                      }}
                      className="focus:outline-none"
                    >
                      +
                    </button>
                  </div>
                </div>
                <Button miners={true} title="Mint Sloths" title2={value * MINT_PRICE_SOL + " SOL"} disabled={isSoldOut || isMinting || !isActive} onClickHandler={() => onMintNFT(value)} />
              </div>
              :
              <Countdown
                date={mintStartDate}
                onMount={({ completed }) => completed && setIsActive(true)}
                onComplete={() => setIsActive(true)}
                renderer={renderCounter}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
}

const renderCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <span className="text-gray-800 font-bold text-2xl cursor-default">
      Live in {days} days, {hours} hours, {minutes} minutes, {seconds} seconds
    </span>
  );
};

export default Index;
