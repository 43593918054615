import React from "react";
import { Line } from "rc-progress";

function ProgressBar({ percent, redeemed, total }) {
  return (
    <>
      <div className="flex items-center justify-between mt-2">
        <p className="text-sm md:text-baswe leading-5 text-white">Total Sold</p>
        <p className="text-sm md:text-baswe leading-5 text-white">{redeemed}/{total}</p>
      </div>
      <div className="w-full mt-2">
        <Line percent={percent} trailColor="#ffffff" strokeColor="#10B981" />
      </div>
    </>
  );
}

export default ProgressBar;
