import React from "react";

function Index() {
  const collection = [
    {
      img: "images/c1.png",
    },
    {
      img: "images/c2.png",
    },
    {
      img: "images/c3.png",
    },
    {
      img: "images/c4.png",
    },
    {
      img: "images/c5.png",
    },
    {
      img: "images/c6.png",
    },
    {
      img: "images/c7.png",
    },
    {
      img: "images/c8.png",
    },
  ];
  return (
    <div id="collection" className="mx-auto container px-4 xl:px-0">
      <p className="text-white text-3xl md:text-4xl lg:text-5xl font-bold lg:leading-48 f-f-h text-center">
        Collection
      </p>
      <div className="grid sm:grid-cols-2 xl:grid-cols-4 mt-10 gap-5">
        {collection.map((item, i) => {
          return <img className="w-full" alt={i} src={item.img} />;
        })}
      </div>
    </div>
  );
}

export default Index;
