import React from "react";

function index({
  title,
  title2,
  onClickHandler = null,
  disabled = false,
  isLoading = false,
  purchase = false,
  miners = false,
}) {
  return (
    <button
      onClick={(e) => {
        onClickHandler && !isLoading && onClickHandler();
      }}
      disabled={disabled}
      className={`${purchase && "py-3 md:py-4 w-170 md:w-219 rounded"} ${
        miners
          ? "text-xs md:text-sm w-full rounded-lg py-18 px-4 md:px-6 lg:px-7 justify-between"
          : "text-sm md:text-base font-bold"
      } ${disabled ? "bg-gray-800 cursor-not-allowed" : "grad"} focus:outline-none text-white py-4 px-4 md:px-6 lg:px-7 rounded   md:leading-4 flex items-center justify-center`}
    >
      {title}
      <p>{title2}</p>
    </button>
  );
}

export default index;
